import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const MainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 0;

  &.adresse {
    width: 100%;
  }

  svg {
    height: 40px;
    width: auto;
    fill: #7ef6fc;
    margin-bottom: 5px;
  }

  a {
    color: #fff;
  }

  a:hover {
    color: #7ef6fc;
  }

  @media (min-width: 1200px) {
    width: 33.33%;

    &.adresse {
      width: 33.33%;
    }
  }
`;

const ContactIcons = () => {
  return (
    <MainWrapper>
      <Icon>
        <a href="mailto:oink@webseitenbauer.ch">
          <div className="icon">
            <svg
              id="uuid-97e5998d-2f93-4b39-bd88-b5de51c36c03"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 81.48 72.27"
            >
              <path d="M2.21 64.6c.15 2.25.21 4.53 2.12 6.03 1.75 1.38 4.02 1.26 6.14 1.3 3.35.06 34.51.61 46.74.16 2.96-.11 5.91-.23 8.87-.38 3.04-.15 6.36.02 9.34-.59 5.11-1.03 4.88-6.25 5.17-10.42.71-10.01.86-20.45-.7-30.44 1.17-.58 1.95-1.8 1.39-3.42-.87-2.53-4.58-4.2-6.66-5.58-4.24-2.81-8.53-5.54-12.79-8.32C55.36 8.73 48.89 4.55 42.39.39c-.9-.58-2.15-.53-3.06 0-12.6 7.4-25.21 14.79-37.81 22.19-.9.53-1.57 1.58-1.52 2.65.49 10.42 1.09 20.84 1.72 31.25.16 2.71.31 5.42.48 8.12Zm5.33 1.68c-.26-.88-.24-8.34-.31-10.81-.26-8.49-.52-16.98-.87-25.46 2.14 1.54 4.55 2.83 6.59 4.06 3.92 2.35 7.7 4.98 11.7 7.19 1.25.69 2.5 1.4 3.74 2.11-4.31 3.79-8.08 8.21-11.86 12.51-2.99 3.4-5.83 7.1-8.98 10.4Zm59.14-.07c-2.76.11-5.52.21-8.28.29-10.44.31-20.91.58-31.36.56-5.32 0-16.57-.2-17.01-.18 3.12-2.7 22.32-20.84 22.45-21.08.58.36 5.21 3.59 7.13 4.31 1.06.4 1.85.32 2.92-.04 2.16-.73 4.28-1.88 6.35-2.96 3.83 4.58 9.03 8.46 13.45 12.32 2.25 1.97 4.48 4.01 6.82 5.88.4.32.81.65 1.22.99-1.42-.01-2.84-.12-3.69-.09Zm9.97-33.99c-.6 6.68-.61 13.47-.97 20.14-.11 2.08-.17 11.12-.94 12.67-.67-.78-6.59-6.87-8.85-9.17-2.31-2.36-9.78-10.16-11.27-11.63 1.99-.99 3.99-1.98 5.97-2.98 5.49-2.78 10.81-5.82 16.06-9.02Zm-3.85-4.81c-4.55 2.96-31.47 16.63-31.9 17.14-1.86-1.09-30.04-18.05-32.27-19.03C19.36 19.21 30.09 12.9 40.81 6.58c5.51 3.5 31.07 20.2 31.99 20.84Z" />
            </svg>
          </div>
          <div className="content">oink@webseitenbauer.ch</div>
        </a>
      </Icon>
      <Icon>
        <a href="tel:+41445865530">
          <div className="icon">
            <svg
              id="uuid-3aa5c52c-21f1-4883-bf8e-51cf9ab6cdc8"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 80.92 88.26"
            >
              <path d="M71.93 54.86c-3.13-2.6-6.51-5.23-10.38-6.26-.75-.2-1.49-.3-2.2-.3-2.77 0-4.94 1.5-6.97 3.16-4.89-3.22-9.71-6.62-14.14-10.4-3.88-3.31-7.41-7.07-10.8-10.91 2.66-1.8 5.33-3.94 6.18-7.38.69-2.8-.33-4.36-1.62-6.34l-.04-.07c-3.05-4.68-7.18-10.59-12.19-14.82C18.55.51 17.21-.02 15.81-.02c-3.97 0-7.54 4.07-9.89 7.14C2.49 11.64-.55 16.18.09 22.14c.88 8.15 6.72 15.73 10.99 21.27 6.03 7.83 12.8 14.74 20.11 20.56 5.79 4.6 12.56 9.81 19.85 13.61-3.77 3.87-9.22 6.59-14.6 6.07-.96-.09-1.88-.32-2.72-.7.46-.53.9-1.08 1.28-1.66 1.83-2.76 3.9-8.54-1.06-9.19-4.4-.58-7.51 4.2-6.47 8.16.28 1.05.72 1.96 1.29 2.74-1.98 1.28-4.37 2.05-6.73 1.95-.89-.04-1.77-.18-2.55-.48.97-.7 1.86-1.47 2.63-2.29 2.68-2.82 3.62-9.02-1.53-9.82-5.05-.78-7.06 4.95-6.97 8.98.02.79.16 1.51.39 2.16-3.82 1.43-8.56-.58-6.98-5.3.31-.93-.93-1.54-1.58-.92-3.1 2.97-1.01 7.84 2.5 9.38 2.65 1.16 5.52.83 8.15-.27 1.98 1.54 4.85 2.1 7.53 1.84 2.76-.27 5.4-1.34 7.62-2.99 2.72 1.62 6.35 1.96 9.49 1.46 5.34-.87 10.01-3.64 13.58-7.57 10.63 3.59 18.48-.74 23.06-5.21 3.64-3.55 4.53-7.46 2.42-10.73-2.15-3.35-5.34-6.19-7.87-8.3ZM18.41 76.54c1.51-1.28 2.83.47 1.99 2-.42.77-1.22 1.42-1.88 1.98-.46.39-.94.77-1.43 1.14-.02-.11-.04-.22-.06-.34-.16-1.46.22-3.8 1.37-4.77Zm14.11-1.12c1.13-.14 1.31 1.29.98 1.77-.12.6-1.73 3.11-2.27 3.7-.97-1.54-1.45-5.29 1.29-5.47ZM20.16 21.71c-.5-.57-1.41-.52-1.9.08-.26.32-.35.72-.27 1.1 2.15 9.85 9.11 17.12 15.63 22.93 7.42 6.6 15.72 12 25.02 17.87.58.36 1.18.55 1.79.55 1.44 0 2.77-1.08 3.22-2.64.44-1.54-.12-3.03-1.47-3.88 0 0-3.95-2.49-5.29-3.35.69-.44 1.4-.73 2.2-.73.59 0 1.2.15 1.87.45 2.62 1.2 14.71 10.79 13.64 12.66.03.39-1.08 1.8-2.5 2.96-4.24 3.5-8.09 4.27-12.82 3.59-5.72-.82-18.72-10.52-23.05-13.8C22.72 49.27 12.29 37.98 6.06 26.86c-2.61-4.66-2.9-8.58-.88-11.96 1.14-1.91 7.67-10.32 10.38-9.85h.14c.82.1 13.78 14 14.44 14.97 1.11 1.65-4.62 5.77-5.61 6.77-.49-.57-3.41-3.98-4.38-5.1Z" />
            </svg>
          </div>
          <div className="content">+41 44 586 55 30</div>
        </a>
      </Icon>
      <Icon className="adresse">
        <div className="icon">
          <svg
            id="uuid-71a2a3d3-2be5-4b47-adf6-f94b8969303c"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 89.39 88.94"
          >
            <path d="M84.12 36.21c-2.31-1.87-4.74-4.46-7.53-5.6-2.94-1.21-6.72-.62-9.83-.63-3.94 0-7.93-.2-11.85.19L56.86 2.3c.1-1.44-1.55-2.67-2.9-2.21-4.47 1.51-8.95 2.22-13.67 2.1-1.21-.03-2.25 1.05-2.24 2.24.01 2.73.06 5.46.11 8.18-3.72-.67-7.5-.69-11.26-.9-3.39-.18-7.3-.97-10.65-.14-2.87.71-4.56 2.73-6.41 4.86a47.907 47.907 0 0 1-8.63 7.77c-1.3.91-1.69 2.68-.5 3.9 2.27 2.31 4.44 4.82 6.86 6.97 1.73 1.54 3.14 1.56 5.38 1.22 7.26-1.1 14.19.07 21.42-.14 2.39-.07 2.87-3.44.56-4.15-5.47-1.68-11.99-1.59-17.64-1.17-2 .15-5.18 1.15-6.65.3-1.59-.93-3.11-2.73-4.53-4.4 1.6-1.22 3.14-2.52 4.58-3.93 2.04-2.01 4.06-5.76 6.8-6.75 2.99-1.08 6.92-.74 10.07-.91 3.56-.19 7.13-.24 10.64-.88.56 23.42 2.59 46.72 4.64 70.05-1.17 1.3-1.1 3.82 1.1 4.38 7.01 1.78 7.38-7.26 7.71-11.83l1.56-22.26c.22.04.44.06.7.04 3.86-.34 7.78-.24 11.64.05 3.04.23 6.61 1.4 9.47-.04 3.12-1.58 5.98-4.39 8.77-6.5 1.85-1.4 5.61-3.24 5.62-6.01 0-2.67-3.52-4.53-5.28-5.95ZM47.61 63.02l-.81 10.56c-.07.92-.6 10.33-1.47 10.08-.22-.07-.44-.1-.65-.11-2.1-25.53-2.18-51.26-2.14-76.86 3.22-.06 6.39-.49 9.5-1.27l-2.91 37.8-1.53 19.8Zm34.21-19.46-3.57 2.64c-1.9 1.4-4.2 4.01-6.67 4.1-2.76.1-5.83-.75-8.62-.9-3.02-.17-6.04-.11-9.06-.11-.12 0-.22.02-.33.03l1.14-16.22c5.47.87 11.1.8 16.62 1.17 2.95.2 4.42 1.13 6.67 2.99.32.27 4.88 3.75 6.14 4.95-.81.25-2.53 1.5-2.32 1.34Z" />
          </svg>
        </div>
        <div className="content">
        Die Webseiten Farm im Säuliamt
        </div>
      </Icon>
    </MainWrapper>
  );
};

export default ContactIcons;
