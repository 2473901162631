import React, { useState } from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useForm, ValidationError } from "@formspree/react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import ContactIcons from "../components/contactIcons";

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 150px);
    padding: 120px 50px 20px 50px;
  }
`;

const ContentWrapper = styled.div`
  background: #44475a;
  border-bottom: 10px solid #7ef6fc;
  position: relative;
  padding: 20px;
  @media(min-width: 768px) {
    padding: 35px 50px;
  }

  @media(min-width: 1600px) {
    padding: 50px;
  }

  @media (min-width: 1200px) {
    width: ${(props) => (props.imageOn ? "10%" : "50%")};
    filter: ${(props) => (props.imageOn ? "blur(20px)" : "blur(0)")};
    transform: ${(props) =>
      props.imageOn ? "translateX(100px)" : "translateX(0)"};
    transition: 0.4s all cubic-bezier(0,1.74,.77,1.06);
    height: 75vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: flex-end;
  }

  @media (min-width: 1920px) {
    height: 65vh;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    height: 100% !important;
    z-index: 0;
    filter: blur(80px);
    opacity: 0.6;
  }
`;

const ContentInner = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  position: relative;
  z-index: 9;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    max-height: 75vh;
  }

  @media (min-width: 1920px) {
    max-height: 55vh;

  }
`;

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    height: 450px !important;
    width: auto !important;
  }

  @media (min-width: 768px) {
    .gatsby-image-wrapper {
      height: 500px !important;
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    width: ${(props) => (props.imageOn ? "90%" : "50%")};
    transition: 0.4s all cubic-bezier(0,1.74,.77,1.06);
    height: calc(75vh - 35px);
    border: 3px solid #7ef6fc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;
    overflow: hidden;

    .gatsby-image-wrapper {
      height: calc(75vh - 35px) !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: 1300px) {
    height: 80vh;
    .gatsby-image-wrapper {
      height: 80vh !important;
    }
  }
`;

const Form = styled.form`
  max-width: 800px;
  margin: 0 auto;

  @media(min-width: 768px) {
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  }
`;

const InputField = styled.input`
  width: calc(100% - 20px);
  margin: 10px;
  height: 50px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  color: #fff;

  &::placeholder {
    color: #7ef6fc;
  }
  @media(min-width: 768px) {
    width: calc(50% - 30px);
  }
`;

const InputLabel = styled.label``;
const InputArea = styled.textarea`
  width: calc(100% - 20px);
  margin: 10px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  color: #fff;

  &::placeholder {
    color: #7ef6fc;
  }
`;

const SubmitBtn = styled.button`
  display: block;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px 20px;
  background: transparent;
  font-family: "Dominique", sans-serif;
  font-size: 30px;
  font-weight: bold;
  border: 2px solid #fff;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 60px;
  transition: all 0.3s ease-out;

  &:hover {
    background: #7ef6fc;
    color: #282a36;
    cursor: pointer;
  }

  @media(min-width: 1200px) {
    margin-bottom: 0;
  }
`;

const SuccessMessage = styled.div`

font-size: 22px;
color: #7ef6fc;
`;

const LegalLinks = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  padding: 10px;
  text-align: center;
  width: 100%;
`

const KontaktTemplatePage = ({ data, imageOn, location }) => {
  const [image, setImage] = useState(false);
  const [state, handleSubmit] = useForm("xpzbjpjo");
  //   const [isSubmitting, setIsSubmitting] = useState(false)
  //   const [sent, setSent] = useState(false)

  return (
    <Layout path={location.pathname}>
      <MainWrapper imageOn={imageOn}>
        <ContentWrapper imageOn={image}>
          <ContentInner forceVisible="y" autoHide={false}>
            <h1>Kontakt</h1>
            <ContactIcons/>
            {state.succeeded ? (
              <SuccessMessage>Vielen Dank für deine Nachricht. Wir melden uns direkt nachdem wir die Kühe wieder eingefangen haben. </SuccessMessage>
            ) : (
              <Form onSubmit={handleSubmit}>
                <InputLabel htmlFor="name">
                  <span className="visually-hidden">Name</span>
                </InputLabel>
                <InputField
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />

                <InputLabel htmlFor="email">
                  <span className="visually-hidden">Email Address</span>
                </InputLabel>
                <InputField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="E-Mail"
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />

                <InputArea
                  id="message"
                  name="message"
                  placeholder="Nachricht"
                  rows="6"
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />

                <SubmitBtn type="submit" disabled={state.submitting}>
                  Senden
                </SubmitBtn>
              </Form>
            )}
            
          </ContentInner>
          <LegalLinks>
            <Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutz</Link> | <Link to="/allgemeine-geschaeftsbedingungen">AGB’s</Link>
            </LegalLinks>
          <GatsbyImage
            image={getImage(data.sanityKontakt.image.asset)}
            alt={data.sanityKontakt.image.asset.altText}
          />
        </ContentWrapper>

        <ImageWrapper imageOn={image} onClick={() => setImage(!image)}>
          <GatsbyImage
            image={getImage(data.sanityKontakt.image.asset)}
            alt={data.sanityKontakt.image.asset.altText}
          />
        </ImageWrapper>
      </MainWrapper>
    </Layout>
  );
};

export const query = graphql`
  query KontaktQuery {
    sanityKontakt {
      image {
        asset {
          url
          altText
          gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        }
      }
      seo {
        title
        description
      }
    }
  }
`;

export default KontaktTemplatePage;
